<template>
  <div class="admin-feed-block">
    <AdminStatisticsFilter :statistics="statistics" :title="$locale['statistics_label']" />
    <div :class="`statistics-block __general ${loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          :icon="false"
          :label="lang['total-fans']"
          :rightDesc="`<strong skeleton>${number_format(statistics.totalUsersFans)}</strong>`"
          @click="setQuery({ admin: 'statistics-fans', startDate, endDate, period: 'custom' })"
        />
        <LargeButton
          :icon="false"
          :label="lang['total-creators']"
          :rightDesc="`<strong skeleton>${number_format(statistics.totalUsersCreators)}</strong>`"
          @click="setQuery({ admin: 'statistics-creators', startDate, endDate, period: 'custom' })"
        />
        <LargeButton
          :icon="false"
          :label="lang['total-sales-credits']"
          :rightDesc="`<strong skeleton>${number_format(statistics.totalSalesOnCredits)}</strong>`"
          @click="setQuery({ admin: 'statistics-sales', startDate, endDate, period: 'custom' })"
        />
        <LargeButton
          :icon="false"
          :label="lang['total-sales-dollars']"
          :rightDesc="`<strong skeleton>$ ${number_format(statistics.totalSalesOnDollars, 2)}</strong>`"
          @click="setQuery({ admin: 'statistics-sales', startDate, endDate, period: 'custom' })"
        />
      </div>
    </div>

    <AdminStatisticsGeneralPosts :statistics="statistics" />

    <Spacer num=".5" />
  </div>
</template>

<script>
import AdminStatistics from "./AdminStatistics";
export default {
  mixins: [AdminStatistics],
  components: {
    AdminStatisticsFilter: () => import("./AdminStatisticsFilter.vue"),
    AdminStatisticsGeneralCard: () => import("./AdminStatisticsGeneralCard.vue"),
    AdminStatisticsGeneralPosts: () => import("./AdminStatisticsGeneralPosts.vue"),
  },
  data: function() {
    return {
      loading: true,
    };
  },
  methods: {
    openFilter: function() {
      this.$refs.context.open();
    },
    setPeriod: function(data) {
      const { period, startDate, endDate } = typeof data === "string" ? { period: data } : data;
      this.setQuery({ period, startDate, endDate });
    },
    getQuery: function() {
      const { period } = this.$route.query;
      let query = `?period=${period}`;
      query += `&startDate=${this.startDate}&endDate=${this.endDate}`;
      return query;
    },
    getStatistics: async function() {
      this.isLoading(true);
      try {
        const query = this.getQuery();
        const response = await this.$api.get(`${process.env.VUE_APP_API_HOST}admin/statistics${query}`);
        this.$store.commit("setAdminHomeStatistics", { statistics: response.data?.statistics });
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
  },
  mounted: function() {
    this.isLoading(true);
    setTimeout(() => {
      this.getStatistics();
    }, 1000);
  },
  computed: {
    lang: function() {
      return this.$locale.statistics;
    },
    period: function() {
      return this.$route.query.period || "last-6-months";
    },
    periodLabel: function() {
      const fromDate = this.$global.dateLocaleFormat(this.statistics.startDate);
      const toDate = this.$global.dateLocaleFormat(this.statistics.endDate);
      return `${fromDate}, ${toDate}`;
    },
    statistics: function() {
      return this.$store.getters.admin.home;
    },
  },
  watch: {
    "$route.query": function() {
      this.getStatistics();
    },
  },
};
</script>
